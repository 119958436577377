module.exports = {
    appVersion: "1.0.17.9",
    SPORTSRADAR_CLIENT_ID: "STM-AAAAKO",
    CONTACT_NO: "+1 (249) 488-0891",
    WHATSAPP_NO: "+12494880891",
    NEW_WHATSAPP_NO: "447938442927",
    genderItems: ["Male", "Female", "I prefer not to say"],
    landingPagePromotionVideo: "/landing-promotion.mp4",
    paymentUrl: "https://payments.besmart5.com/v2/renderpaymentgateway",
    AstroPayUrl: "https://payments.besmart5.com/v2/renderastropay",
    praxisUrl: "https://payments.besmart5.com/v2/renderpraxis",
    paymentUrlBangla:
        "https://admin.paymentgateway-bd.fairplay.club/v2/renderpaymentgateway",
    // paymentUrlBangla:
    //  'https://bd.paymentgateway.fairplay.club/v2/renderpaymentgateway',
    AstroPayUrlBangla:
        "https://admin.paymentgateway-bd.fairplay.club/v2/renderastropay",
    shareModalDateRange: 10,
    fpsshareStatusModalVideo:
        "https://www.youtube.com/embed/zENSVylmi6M?autoplay=1&mute=1",
    shareStatusModalVideo:
        "https://www.youtube.com/embed/wFCTryrrWBQ?modestbranding=1&showinfo=0&rel=0&autoplay=1&loop=1&fs=1",
    videoTitle:
        "Now share your referral code with your friends and earn hefty bonuses in a few simple clicks!",
    fpsVideoTitle: "Referral Bonus - How to WIN BIG risk-free?",
    resendcodeSec: 30,
    referralsPercentage: "15%",
    isSportsRadar: true, // For UAT its false ..for prod its true always
    s3Url: "https://nn1eku1xgk.execute-api.ap-south-1.amazonaws.com/uploadToS3",
    callextensions: ["9999997"],
    limitForTopBet: 25,
    fromDateForTopBet: 7,
    verifySms: true,
    verifyStandardOTP: "123456",
    langDropDown: true,
    balanceCheck: 10000000000000000000000000,
    whatsappLink: "https://wa.link/fpapi",
    indexLink: "https://thefairplay.io",
    bonusExpireDate: 21,
    maxWeeklyAvgBonus: 25000,
    maxBnkDepBonus: 25000,
    maxFrstDepBonus: 50000,
    maxRefrlBonus: 50000,
    silver: {
        maxDepositVal: 50000,
        maxPSB: 50000,
        maxLossback: 50000,
        maxSportsBook: 50000
    },
    gold: {
        maxDepositVal: 50000,
        maxPSB: 50000,
        maxLossback: 50000,
        maxSportsBook: 50000
    },
    platinum: {
        maxDepositVal: 50000,
        maxPSB: 50000,
        maxLossback: 50000,
        maxSportsBook: 50000
    },
    walletBalanceLimit: 100000,
    referralAmount: "200",
    loadComponentsOnScroll: true,
    affiliatesCodeArray: [
        "dgcas",
        "dg_quicksb",
        "dg_quickCas",
        "dg_sb",
        "new_casdg",
        "ZEB",
        "test_dg",
        "dg",
        "dg_fpta",
        "dg_fptb",
        "dg_fptc",
        "dg_fptp",
        "dg_fptd",
        "dg_fpte",
        "dg_fptf",
        "dg_fptg",
        "dg_fpth",
        "dg_fptj",
        "dg_fptz",
        "dg_fptx",
        "dg_fptv",
        "dg_fptn",
        "dg_fptm",
        "dg_fptl",
        "dg_fptk",
        "dg_fptq",
        "dg_fptw",
        "dg_fptr",
        "dg_fptt",
        "dg_fptu",
        "dg_fpt",
        "dg_fptu",
        "dg_fpti",
        "dg_fpto",
        "a_9b_24c",
        "a_9b_23c",
        "a_9b_23c_",
        "a_9b_24c_",
        "dm_fbtest2",
        "meta_dg",
        "fb_newtest",
        "Yellow"
    ],
    showLiveTv: false,
    fantasyProShow: false,
    quicksignup1link: "https://wa.link/swdigigoogle",
    quicksignup2link: "https://wa.link/swdigimeta",
    quicksignup3link: "https://wa.link/swsportsradar",
    quicksignup4link: "https://wa.link/swcricbuzz",
    userRegistrationLink: "https://wa.link/swdigimeta",
    quickJoinUs1Link: "https://wa.link/digitalaudio",
    quickJoinUs2Link: "https://wa.link/cricbuzz2",
    enableUofAPIs: false,
    showHrAndGhr: true,
    multibetlayBookHorseId: 1000001
}
