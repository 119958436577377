<template>
    <v-app
        :style="
            $vuetify.theme.dark
                ? ''
                : { backgroundImage: 'url(' + backgroundImage + ')' }
        "
        :class="$vuetify.theme.dark ? '' : 'light-pattern'"
    >
        <!-- <CacheClearModal /> -->
        <div>
            <component :is="CacheClearModal"></component>
        </div>
        <div v-if="isLoaded">
            <component :is="shareModal"></component>
        </div>
        <div v-if="isLoaded">
            <component :is="FairplayNotification"></component>
        </div>
        <!-- <div v-if="isLoaded">
            <component :is="NotificationMarquee"></component>
        </div> -->
        <div v-if="isLoaded">
            <component :is="NetworkBar"></component>
        </div>
        <!-- <Drawer /> -->
        <div v-if="isLoaded && $breakpoint.smAndDown">
            <component :is="megaMenuDrawer"></component>
        </div>
        <div v-if="isLoaded && $breakpoint.smAndDown">
            <component :is="mobileDrawer"></component>
        </div>
        <div v-if="isLoaded">
            <component :is="Drawer"></component>
        </div>
        <!-- <header-bar /> -->
        <div v-if="isLoaded">
            <component :is="HeaderBar"></component>
        </div>
        <v-main
            class="main-section"
            :class="{
                'pb-15 mt-20': $breakpoint.smAndDown,
                'pt-20': !$breakpoint.smAndDown,
                'mb-12': this.$route.name !== 'live-casino-system-pageNo'
            }"
        >
            <component :is="snackbar"></component>
            <v-lazy>
                <nuxt />
            </v-lazy>
        </v-main>
        <div
            v-if="
                (isLoaded && $route.name === 'index') ||
                $route.name === 'favorites' ||
                $route.name === 'inplay' ||
                $route.name === 'sportsbook-category' ||
                $route.name === 'sportsbook' ||
                $route.name === 'sportsbook-category-subCategory-id' ||
                $route.name === 'sportsbook-category-subCategory-id-betradar' ||
                $route.name === 'sportsbook-category-id'
            "
        >
            <component :is="OneclickBet"></component>
        </div>
        <!-- <div
            v-if="isLoaded && $breakpoint.smAndDown"
            id="fav_marquee"
            class="fav-marquee"
        >
            <component :is="FavoritesMarquee"></component>
        </div> -->
        <div
            v-if="isLoaded && setUp && setUp === 'gb' && domainType === 'hide'"
        >
            <component :is="PlaceBetModal"></component>
        </div>
        <div
            v-if="
                isLoaded && $route.name && $route.name.startsWith('sportsbook')
            "
        >
            <component :is="LowBalanceModal"></component>
        </div>
        <div v-if="isLoaded && $breakpoint.smAndDown">
            <component :is="navBar" class="premium-topbar"></component>
        </div>
        <!-- <FooterBar /> -->
        <div v-if="isLoaded">
            <component :is="FooterBar"></component>
        </div>
        <div v-if="isLoaded && $auth.user && $auth.user._id" class="d-none">
            <component :is="Ipquality"></component>
        </div>
    </v-app>
</template>

<script>
import eventScript from "@/services/script"
import { indexLink } from "~/constant"
export default {
    name: "Default",
    middleware: ["maintenanceCheck", "favIconCheck", "fairplaySport"],
    data() {
        return {
            domainType: process.env.APP_TITLE,
            setUp: process.env.setUp,
            isLoaded: false,
            component: null,
            CacheClearModal: null,
            shareModal: null,
            FairplayNotification: null,
            // NotificationMarquee: null,
            NetworkBar: null,
            Drawer: null,
            HeaderBar: null,
            OneclickBet: null,
            // FavoritesMarquee: null,
            PlaceBetModal: null,
            LowBalanceModal: null,
            Ipquality: null,
            snackbar: null,
            FooterBar: null,
            megaMenuDrawer: null,
            mobileDrawer: null,
            navBar: null,
            CDN_IMG_URL: process.env.CDN_IMG_URL,
            backgroundImage: ""
        }
    },
    head() {
        return {
            link: [
                {
                    rel: "canonical",
                    href:
                        this.$route.path !== "/"
                            ? process.env.canonicalUrl + this.$route.path
                            : process.env.canonicalUrl
                }
            ]
        }
    },
    computed: {
        version() {
            return this.$store.state.profile.appversion
        }
    },
    created() {
        this.$store.dispatch("languageMaster/getAllLanguages")
        this.onloadchat()
        this.backgroundImage = `${this.CDN_IMG_URL}/light-pattern.webp`
    },
    mounted() {
        if (
            sessionStorage.getItem("isVpnDetected") &&
            sessionStorage.getItem("isVpnDetected") !== "null"
        ) {
            this.$store.commit("snackbar/open", {
                text:
                    sessionStorage.getItem("isVpnDetected") ||
                    "Unable to proceed due to use of VPN!!!",
                color: "error"
            })
            sessionStorage.removeItem("isVpnDetected")
        }
        this.loadComponent()
    },
    methods: {
        onlaodMethods() {
            if (process.client) {
                if (
                    !indexLink.includes(window.location.origin) &&
                    process.client
                ) {
                    eventScript.noindexScript()
                }
            }
        },
        onloadchat() {
            try {
                if (process.client) {
                    if (this.$auth.user && this.$route.path !== "/") {
                        window.addEventListener("load", function (e) {
                            const classname = document.getElementById(
                                "mobile-chat-container"
                            )
                            if (classname && classname.classList) {
                                classname.classList.remove(
                                    "mobile-chat-container"
                                )
                                classname.classList.add("d-none")
                            }
                            // const chatNew = document.getElementsByClassName(
                            //     "intercom-lightweight-app"
                            // )[0]

                            // if (chatNew && chatNew.classList) {
                            //     chatNew.classList.add("d-none")
                            // }
                        })
                    }
                }
            } catch (error) {}
        },
        async loadComponent() {
            await import("@/plugins/textToIcon.js")
            await import("@/plugins/betslipOpener.js")
            await import("@/plugins/vue-social-sharing.js")
            await import("@/plugins/encryption.js")

            // SET 1
            const { default: HeaderBar } = await import(
                "@/components/fairplay/layouts/HeaderBar"
            )
            this.HeaderBar = HeaderBar

            this.isLoaded = true
            // SET 7
            const { default: FooterBar } = await import(
                "@/components/fairplay/layouts/FooterBar"
            )
            this.FooterBar = FooterBar

            // SET 3
            // const { default: NotificationMarquee } = await import(
            //     "@/components/NotificationMarquee"
            // )
            // this.NotificationMarquee = NotificationMarquee

            // SET 4
            const { default: NetworkBar } = await import(
                "@/components/layouts/NetworkBar"
            )
            this.NetworkBar = NetworkBar

            // SET 4
            const { default: Drawer } = await import(
                "@/components/fairplay/layouts/Drawer"
            )
            this.Drawer = Drawer

            const { default: megaMenuDrawer } = await import(
                "@/components/layouts/megaMenuDrawer"
            )
            this.megaMenuDrawer = megaMenuDrawer

            const { default: MobileDrawer } = await import(
                "@/components/layouts/mobileDrawer"
            )
            this.mobileDrawer = MobileDrawer

            // SET 4
            const { default: snackbar } = await import(
                "@/components/layouts/Snackbar"
            )
            this.snackbar = snackbar

            // SET 5
            const { default: NavBar } = await import(
                "@/components/fairplay/layouts/Navbar"
            )
            this.navBar = NavBar

            // SET 1
            const { default: OneclickBet } = await import(
                "@/components/OneclickBet"
            )
            this.OneclickBet = OneclickBet

            // SET 1
            // const { default: FavoritesMarquee } = await import(
            //     "@/components/favorites/FavoritesMarquee"
            // )
            // this.FavoritesMarquee = FavoritesMarquee

            // SET 6
            const { default: PlaceBetModal } = await import(
                "@/components/fairplay/modals/PlaceBetModal"
            )
            this.PlaceBetModal = PlaceBetModal

            // SET 6
            const { default: LowBalanceModal } = await import(
                "@/components/fairplay/modals/LowBalanceModal"
            )
            this.LowBalanceModal = LowBalanceModal

            // SET 6
            const { default: Ipquality } = await import(
                "@/components/ipquality"
            )

            // SET 0
            const { default: CacheClearModal } = await import(
                "@/components/fairplay/modals/CacheClearModal"
            )
            this.CacheClearModal = CacheClearModal

            // SET 1
            const { default: shareModal } = await import(
                "@/components/ShareModal"
            )
            this.shareModal = shareModal

            // SET 2
            const { default: FairplayNotification } = await import(
                "@/components/fairplay/FairplayNotification"
            )
            this.FairplayNotification = FairplayNotification

            this.Ipquality = Ipquality
            try {
                if (!document.getElementById("OneSignalSDK")) {
                    // One Signal start Here
                    const oneSignal = document.createElement("script")
                    oneSignal.setAttribute("id", "OneSignalSDK")
                    oneSignal.setAttribute(
                        "src",
                        "https://cdn.onesignal.com/sdks/OneSignalSDK.js"
                    )
                    await document.head.appendChild(oneSignal)
                    if (process.client && process.env.OS_CODE) {
                        /* eslint-disable */
                        window.OneSignal = window.OneSignal || []
                        OneSignal.push(function () {
                            OneSignal.init({
                                appId: process.env.OS_CODE
                            })
                        })
                    }
                    // One Signal End Here
                }
                this.$freshchat.load()
            } catch (error) {}
            this.blockUserIp()
            this.maintenanceCheck()
            this.$store.dispatch("profile/getDeploymentVersion")
        },
        blockUserIp() {
            this.$store.dispatch("ip/blockUserIp")
            console.log("Page is fully loaded! starting!!!!!!")
            this.onlaodMethods()
            if (process.client) {
                window.addEventListener("load", function (e) {
                    console.log("Page is fully loaded!")
                    eventScript.facebookOverallPixel()
                    eventScript.affiliateVendorScript()
                })
            }
        },
        async maintenanceCheck() {
            const securedPaths = ["/maintenance", "/maintenance/"]
            if (!securedPaths.includes(this.$route.path)) {
                if (!this.$store.state.profile.underMaintenance.checked) {
                    await this.$store.dispatch("profile/getMaintenance")
                }
                if (this.$store.state.profile.underMaintenance.value) {
                    this.$router.push("/maintenance")
                }
            }
            const securedWalletPaths = [
                "/wallet",
                "/wallet/",
                "/wallet/deposit",
                "/wallet/deposit/",
                "/wallet/withdrawal",
                "/wallet/withdrawal/"
            ]
            if (securedWalletPaths.includes(this.$route.path)) {
                if (!this.$store.state.profile.walletUnderMaintenance.checked) {
                    await this.$store.dispatch("profile/getMaintenance")
                }
                if (this.$store.state.profile.walletUnderMaintenance.value) {
                    this.$store.commit("profile/set_showMaintenanceModal", true)
                    this.$router.push("/")
                }
            }
        }
    }
}
</script>
<style scoped>
.light-pattern {
    background-repeat: repeat;
    background-size: 55px;
}
</style>
